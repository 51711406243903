import './header.css';

import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import * as Select from '@radix-ui/react-select';
import { Link } from '@radix-ui/themes';
import GBLogo from '../../assets/gb.svg';
import LTLogo from '../../assets/lt.svg';
import GuruPayLogo from '../../assets/GuruPay_logo_2_transparent_background.png';
import { LogoutIcon } from '../icons';
import IconButton from '../iconButton';
import { useAuth } from '../../hooks';
import { useTranslation } from 'react-i18next';

const languages = [
	{ label: 'English', value: 'en', logo: GBLogo },
	{ label: 'Lietuvių', value: 'lt', logo: LTLogo },
];

const Header = () => {
	const { t, i18n } = useTranslation(undefined, { keyPrefix: 'layout' });
	const { logout } = useAuth();

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
	};

	return (
		<div
			style={{
				width: '100vw',
				background: 'white',
				height: '110px',
				boxShadow: '0px 15px 45px 0px #2D282E14',
			}}
		>
			<div
				style={{
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					padding: '0px 80px',
				}}
			>
				<img
					src={GuruPayLogo}
					style={{
						// marginLeft: '80px',
						marginTop: '6px',
						height: '60px',
					}}
					alt="GuruPay logo"
				/>
				<div
					style={{
						width: '138px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						// marginRight: '24px',
					}}
				>
					<Link
						href={'https://gurupayhelp.zendesk.com/hc/en-us'}
						target="_blank"
						style={{ color: 'black', fontWeight: 600, fontSize: '14px' }}
					>
						{t('help')}
					</Link>
					<Select.Root value={i18n.language} onValueChange={changeLanguage}>
						<Select.Trigger className="SelectTrigger">
							<Select.Value>
								<img
									src={languages.find((x) => x.value === i18n.resolvedLanguage)?.logo} // TODO fix
									style={{ width: '20px', height: '20px', borderRadius: '50%' }}
									alt="Language logo"
								/>
							</Select.Value>
							<Select.Icon className="SelectIcon">
								<ChevronDownIcon />
							</Select.Icon>
						</Select.Trigger>
						<Select.Portal>
							<Select.Content position="popper" className="SelectContent">
								<Select.ScrollUpButton className="SelectScrollButton">
									<ChevronUpIcon />
								</Select.ScrollUpButton>
								<Select.Viewport className="SelectViewport">
									{languages.map((lang) => (
										<Select.Item
											key={lang.value}
											className={'SelectItem'}
											value={lang.value}
										>
											<Select.ItemText className={'SelectText'}>
												{lang.label}
											</Select.ItemText>
										</Select.Item>
									))}
								</Select.Viewport>
								<Select.ScrollDownButton className="SelectScrollButton">
									<ChevronDownIcon />
								</Select.ScrollDownButton>
							</Select.Content>
						</Select.Portal>
					</Select.Root>
					<IconButton onClick={logout}>
						<LogoutIcon />
					</IconButton>
				</div>
			</div>
		</div>
	);
};

export default Header;
