import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from '../../hooks';
import { PaginatedItems } from './common.ts';
import { ACCOUNTS_PAGE_SIZE } from './useAccounts.ts';

export interface AccountHistory {
	date: string;
	payer: Payer;
	beneficiary: Beneficiary;
	documentNumber: string;
	details: string;
	endToEnd: string;
	amount: Amount;
	direction: 'outward' | 'inward';
}

export interface Amount {
	amount: number;
	currency: string;
	display: string;
}

export interface Payer {
	id: any;
	name: string;
	accountId: string;
	bic: string;
}

export interface Beneficiary {
	id: string;
	name: string;
	accountId: string;
	bic: string;
}

export interface Pagination {
	limit: number;
	offset: number;
	count: number;
}

export const ACCOUNT_STATEMENT_PAGE_SIZE = 50;

function useHistory(
	currentPage: number,
	limit = ACCOUNTS_PAGE_SIZE,
	customerId?: string,
	accountId?: string,
	from?: string,
	to?: string,
	beneficiary?: string,
	description?: string,
	sortBy?: string,
) {
	const { session } = useAuth();
	const offset = currentPage === 1 ? 0 : (currentPage - 1) * limit;
	const [sortField, sortOrder] = sortBy?.split('-') || ['settled_at', 'desc'];

	const fetchAccounts = async (): Promise<PaginatedItems<AccountHistory[]>> => {
		const response = await axios.get(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${customerId}/accounts/${accountId}/history`,
			{
				params: {
					offset,
					limit,
					...(from && { from }),
					...(to && { to }),
					...(beneficiary && { beneficiary }),
					...(description && { description }),
					...(sortField && { sortField }),
					...(sortOrder && { sortOrder }),
				},
				headers: { Authorization: session?.tokenized },
			},
		);

		return response.data;
	};

	return useQuery({
		queryKey: [
			'history',
			customerId,
			accountId,
			currentPage,
			limit,
			from,
			to,
			beneficiary,
			description,
		],
		queryFn: () => fetchAccounts(),
		enabled: !!customerId && !!accountId,
	});
}

export { useHistory };
