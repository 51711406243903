import { Box, Flex } from '@radix-ui/themes';
import Typography from '../typography/typography.tsx';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { useEffect, useState } from 'react';
import styles from './tfa.module.css';
import Loader from '../loader';
import Button from '../button';
import * as Label from '@radix-ui/react-label';
import {
	useInitiateSigning,
	useSignTransaction,
} from '../../api/hooks/useTransactions.ts';
import { useAuth } from '../../hooks/index.ts';
import Alert from '../alert/index.ts';
import {
	useInitiateSigningBatch,
	useInitiateSigningMultiple,
	useSignBatch,
	useSignMultiple,
} from '../../api/hooks/useBatch.ts';
import { useProfiles } from '../../api/hooks/useProfiles.ts';

const INITIAL_TIME = 120;

enum SigningStatus {
	SUCCESS = 'success',
	ERROR = 'error',
	CANCELED = 'canceled',
}

interface TFAProps {
	transactionId?: string;
	onSign: (status: SigningStatus) => void;
	showCancelButton?: boolean;
	type?: 'transaction' | 'batch' | 'multiple';
	transactionIds?: string[];
	setSigningInitiated?: (status: boolean) => void;
	// onPostponeSign: () => void;
	// onCancel: () => void;
}

const TFA = ({
	showCancelButton = true,
	type = 'transaction',
	setSigningInitiated,
	...props
}: TFAProps) => {
	const { t } = useTranslation('', { keyPrefix: 'tfa' });
	const [otp, setOtp] = useState<string>();
	const [time, setTime] = useState<number>();
	const [isRunning, setIsRunning] = useState(true);
	const [isDisabled, setDisabled] = useState(false);
	const [isSigningDisabled, setSigningDisabled] = useState(true);
	// transaction
	const initiateSigning = useInitiateSigning();
	const signTransaction = useSignTransaction();

	const profile = useProfiles();

	// batch
	const initiateSigningBatch = useInitiateSigningBatch();
	const signBatch = useSignBatch();
	const initiateSigningMultiple = useInitiateSigningMultiple();
	const signMultiple = useSignMultiple();

	const { session } = useAuth();
	const [isVisible, setVisible] = useState(true);

	const [signOperationResponse, setSignOperationResponse] = useState<
		| {
				isError: boolean;
				data: any;
		  }
		| undefined
	>(undefined);

	const onInitiateSigning = () => {
		setSigningInitiated?.(true);
		if (type === 'transaction') {
			initiateSigning.mutate({
				transactionId: props.transactionId,
			});
		}
		if (type === 'batch') {
			initiateSigningBatch.mutate({
				batchId: props.transactionId,
			});
		}
		if (type === 'multiple' && props.transactionIds) {
			initiateSigningMultiple.mutate({
				transactionIds: props.transactionIds,
			});
		}
	};

	useEffect(() => {
		if (signTransaction.isPending || signBatch.isPending || signMultiple.isPending) {
			setSigningDisabled(true);
		}
	}, [signTransaction.isPending, signBatch.isPending, signMultiple.isPending]);

	useEffect(() => {
		if (!signTransaction.data && !signBatch.data && !signMultiple.data) return;
		props.onSign(SigningStatus.SUCCESS);
		initiateSigning.reset();
		initiateSigningBatch.reset();
		initiateSigningMultiple.reset();
	}, [signTransaction.data, signBatch.data, signMultiple.data]);

	useEffect(() => {
		if (!signTransaction.isError && !signBatch.isError && !signMultiple.isError) return;
		props.onSign(SigningStatus.ERROR);
	}, [signTransaction.isError, signBatch.isError, signMultiple.isError]);

	const onSignTransaction = () => {
		if (type === 'transaction') {
			signTransaction.mutate({
				transactionId: props.transactionId,
				code: otp,
			});
		}
		if (type === 'batch') {
			signBatch.mutate({
				transactionId: props.transactionId,
				code: otp,
			});
		}
		if (type === 'multiple' && props.transactionIds && otp) {
			signMultiple.mutate({
				transactionIds: props.transactionIds,
				code: otp,
			});
		}
	};

	useEffect(() => {
		setIsRunning(true);
	}, []);

	useEffect(() => {
		if (otp?.length === 6) {
			setSigningDisabled(false);
		} else {
			setSigningDisabled(true);
		}
	}, [otp?.length]);

	useEffect(() => {
		let interval;

		if (isRunning && time > 0) {
			setDisabled(true);
			interval = setInterval(() => {
				setTime((prevTime) => prevTime - 1);
			}, 1000);
		} else if (time === 0) {
			setIsRunning(false);
			setDisabled(false);
		}

		return () => clearInterval(interval);
	}, [isRunning, time]);

	useEffect(() => {
		setSignOperationResponse({
			isError: false,
			data: signTransaction.data ?? signBatch.data ?? signMultiple.data,
		});
		setSigningInitiated?.(false);
	}, [signTransaction.data, signBatch.data, signMultiple.data]);

	useEffect(() => {
		if (signTransaction.isError || signBatch.isError || signMultiple.isError) {
			setSignOperationResponse({
				isError: true,
				data:
					signTransaction?.error?.response?.data ??
					signBatch?.error?.response?.data ??
					signMultiple?.error?.response?.data,
			});
			setSigningInitiated?.(false);
		}
	}, [signTransaction.isError, signBatch.isError, signMultiple.isError]);

	useEffect(() => {
		if (
			initiateSigning.isSuccess ||
			initiateSigningBatch.isSuccess ||
			initiateSigningMultiple.isSuccess
		) {
			setTime(INITIAL_TIME);
		}
	}, [
		initiateSigning.isSuccess,
		initiateSigningBatch.isSuccess,
		initiateSigningMultiple.isSuccess,
	]);

	const onCancel = () => {
		setVisible(false);
		props?.onSign(SigningStatus.CANCELED);
	};

	const showInputs = () =>
		initiateSigning.isSuccess ||
		initiateSigningBatch.isSuccess ||
		initiateSigningMultiple.isSuccess;

	const isSigningError = () =>
		initiateSigning.isError ||
		initiateSigningBatch.isError ||
		signTransaction.isError ||
		signBatch.isError ||
		initiateSigningMultiple.isError;

	return (
		isVisible && (
			<Flex direction="column" className={styles.container}>
				<Box>
					<Typography level={'h4'}>
						{profile.data?.secondFactorMethod === 'sms' ? t('phone') : t('email')}
					</Typography>
					<Typography level={'text'} className={styles.data}>
						{profile.data?.secondFactorMethod === 'sms'
							? session?.identity?.traits.phone
							: session?.identity?.traits.email}
					</Typography>
				</Box>

				{showInputs() && !signOperationResponse?.isError && (
					<>
						<div className={styles.labelContainer}>
							<Label.Root className={styles.label} htmlFor="pinInputWithLoader">
								{t('pin')}
							</Label.Root>
						</div>
						{/*<Typography level={'h4'}>}</Typography>*/}

						<Flex id={'pinInputWithLoader'} align={'center'} direction={'row'} gap={'6'}>
							<OtpInput
								value={otp}
								onChange={setOtp}
								inputType={'number'}
								numInputs={6}
								renderInput={(props) => <input {...props} />}
								containerStyle={styles.otp}
								inputStyle={styles.otpInput}
							/>

							{isRunning && (
								<>
									<div style={{ position: 'relative' }}>
										<Loader />
									</div>

									<Typography className={styles.data}>
										{profile.data?.secondFactorMethod === 'sms'
											? t('retry', { time: time })
											: t('retryEmail', { time: time })}
									</Typography>
								</>
							)}
						</Flex>
					</>
				)}

				<Flex align={'center'} direction={'row'} className={styles.buttonsContainer}>
					<Button
						onClick={onInitiateSigning}
						disabled={
							isDisabled ||
							initiateSigning.isPending ||
							initiateSigningBatch.isPending ||
							initiateSigningMultiple.isPending
						}
						className={styles.button}
						variant={'primary'}
					>
						{profile.data?.secondFactorMethod === 'sms'
							? t('buttons.send')
							: t('buttons.sendEmail')}
					</Button>
					<Button
						className={styles.button}
						variant={'inverted'}
						onClick={onSignTransaction}
						disabled={isSigningDisabled}
					>
						{t('buttons.sign')}
					</Button>
					{showCancelButton && (
						<Button className={styles.button} variant={'secondary'} onClick={onCancel}>
							{t('buttons.cancel')}
						</Button>
					)}

					{/* <Button className={styles.button} variant={'secondary'}>
					{t('buttons.info')}
				</Button>
				<Button
					className={styles.button}
					variant={'secondary'}
					onClick={props.onPostponeSign}
				>
					{t('buttons.postpone')}
				</Button>
				<Button
					className={styles.button}
					variant={'secondary'}
					onClick={props.onPostponeSign}
				>
					{t('buttons.cancel')}
				</Button> */}
				</Flex>
				{!signOperationResponse?.isError && signOperationResponse?.data && (
					<Alert type="info">{t('alerts.signed')}</Alert>
				)}
				{isSigningError() && <Alert type="error">{t('alerts.failed')}</Alert>}
			</Flex>
		)
	);
};

export { TFA, SigningStatus };
