import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { SwiftPayment } from '../../../api/hooks/types/createTransaction';
import {
	useCreateTransaction,
	useDocumentNumber,
} from '../../../api/hooks/useTransactions';
import styles from './mainData.module.css';
import { useAccounts } from '../../../api/hooks';
import { Collapsible } from '../../../components/collapsible';
import { ScrollableAccountsList } from '../../../blocks/scrollable-accounts-list';
import { useEffect, useMemo, useState } from 'react';
import { Account } from '../../../api/hooks/useAccounts';
import Button from '../../../components/button';
import { CalendarInput, InputDropdown, SimpleInput } from '../../../components/input';
import { useTranslation } from 'react-i18next';
import Typography from '../../../components/typography/typography';
import Switch from '../../../components/switch';
import { Flex, TextArea } from '@radix-ui/themes';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import AdditionalDataForm from './additionalData';
import Alert from '../../../components/alert';
import NewSelect from '../../../components/select/new-select';
import { format } from 'date-fns';
import { useBankInfo, useValidateIBAN } from '../../../api/hooks/useUtilities';
import Loader from '../../../components/loader';
import { TFA, SigningStatus } from '../../../components/tfa/tfa';
import { useGeoDataContext } from '../../../contexts/GeoDataContext';
import { FormBlocker } from '../../../components/blocker/use-blocker';
import { useBankProfile } from '../../../contexts/BankProfileContext';
import { useError } from '../../../contexts/ErrorContext';

const ACCOUNTS_PAGE_SIZE = 10;

const DEFAULT_VALUES = {
	currency: 'EUR',
	payment_type: 'INTERNATIONAL',
	payment_urgency: 'NORMAL',
	fee_type: 'SHA',
	end_2_end: '',
	payment_details: '',
	execution_date: format(new Date(), 'yyyy-MM-dd'),
	beneficiary: {
		name: '',
		account: '',
		address: {
			type: 'STRUCTURED',
			country: undefined,
			line: '',
			town: '',
			building_number: '',
			postal_code: '',
		},
		bank: {
			bic: '',
			name: '',
			address: {
				type: 'UNSTRUCTURED',
				line: '',
				country: undefined,
			},
		},
	},
	ultimate_beneficiary: {
		address: {
			type: 'UNSTRUCTURED',
		},
	},
	correspondent: {
		bic: '',
		name: '',
		address: {
			type: 'UNSTRUCTURED',
			line: '',
			country: undefined,
		},
	},
} as Partial<SwiftPayment>;

const FEE_OPTIONS = [
	{ value: 'SHA', label: 'SHA' },
	// { value: 'OUR', label: 'OUR' },
];

const SwiftPaymentForm = ({ operation }: { operation: any }) => {
	const [fromAccount, setFromAccount] = useState<Account>();
	const documentNumber = useDocumentNumber(fromAccount?.id);
	const [currentPage, setCurrentPage] = useState(1);
	const { canSign } = useBankProfile();

	const {
		setError,
		register,
		handleSubmit,
		formState,
		control,
		watch,
		setValue,
		reset,
		resetField,
		clearErrors,
	} = useForm<SwiftPayment>({
		mode: 'onBlur',
		reValidateMode: 'onChange',
		defaultValues: DEFAULT_VALUES,
		values: {
			...DEFAULT_VALUES,
			account_id: fromAccount?.id || undefined,
			document_number: documentNumber?.data?.documentNumber || undefined,
			...(operation && {
				amount: operation.amount,
				currency: operation.currency,
				payment_details: operation.payment_details,
				beneficiary: {
					name: operation.beneficiary.name,
					account: operation.beneficiary.accountNumber,
					bank: {
						address: {
							type: 'UNSTRUCTURED',
							country: undefined,
						},
					},
					...(operation.beneficiary.address && {
						address: {
							type: 'STRUCTURED',
							country: operation.beneficiary.address?.country,
							line: operation.beneficiary.address?.addressLine,
							postal_code: operation.beneficiary.address?.postalCode,
							town: operation.beneficiary.address?.townName,
							building_number: operation.beneficiary.address?.buildingNumber,
						},
					}),
				},
			}),
		},
	});

	const { t } = useTranslation();

	const createTransaction = useCreateTransaction();
	const accounts = useAccounts(
		currentPage,
		ACCOUNTS_PAGE_SIZE,
		undefined,
		undefined,
		undefined,
		['OPENED', 'CREDIT_BLOCKED'],
	);

	const [isOpenAccounts, setOpenAccounts] = useState(false);
	const [transferInfoType, setTransferInfoType] = useState('description');
	const [filtersOpen, setFiltersOpen] = useState(false);
	const [createOrderResponse, setCreateOrderResponse] = useState<{
		isError: boolean;
		data: any;
	}>();
	const [ibanToValidate, setIbanToValidate] = useState<string | undefined>(undefined);
	const validateIBAN = useValidateIBAN(ibanToValidate, true);
	const bankInfo = useBankInfo(ibanToValidate, validateIBAN?.data?.isValid);
	const geoData = useGeoDataContext();
	const [signingStatus, setSigningStatus] = useState<SigningStatus | undefined>();

	// Block navigating elsewhere when data has been entered into the input

	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' });
	const { errors: errorsContext, clearErrors: clearErrorsContext } = useError();

	const TRANSFER_INFO_TYPES = [
		{
			value: 'description',
			label: t('orders.newPayment.data.transferType.description'),
		},
		{ value: 'refNo', label: t('orders.newPayment.data.transferType.refNo') },
	];

	const errors = useMemo(() => formState.errors, [formState]);

	const onIbanBlur = async (event: any) => {
		const { value } = event.target;
		setIbanToValidate(value);

		if (value) {
			setIbanToValidate(value);
		}
	};

	useEffect(() => {
		if (errorsContext.length > 0) {
			errorsContext.forEach((error) => {
				setError(error.property as keyof SwiftPayment, {
					message: error.message,
				});
			});
		}
	}, [errorsContext.length]);

	useEffect(() => {
		if (ibanToValidate) {
			validateIBAN.refetch();
		}
	}, [ibanToValidate]);

	useEffect(() => {
		if (validateIBAN.isSuccess && validateIBAN.data) {
			if (!validateIBAN.data.isValid) {
				// setError('beneficiary.account', {
				// 	message: t('orders.newPayment.form.accountNoError'),
				// });
			} else {
				clearErrors('beneficiary.account');
			}
		}
	}, [validateIBAN.isSuccess, validateIBAN.data]);

	useEffect(() => {
		if (bankInfo.isSuccess && bankInfo.data) {
			setValue('beneficiary.bank.bic', bankInfo.data.bic);
			setValue('beneficiary.bank.name', bankInfo.data.institutionName);
			setValue('beneficiary.bank.address.country', bankInfo.data.countryCode);
		}
	}, [bankInfo.isSuccess, bankInfo.data]);

	const today = new Date();
	const yearAfter = () => {
		const futureDate = new Date();
		futureDate.setFullYear(futureDate.getFullYear() + 1);
		return futureDate;
	};

	const onSubmit: SubmitHandler<SwiftPayment> = (data) => {
		createTransaction.mutate({ data });
	};

	useEffect(() => {
		if (documentNumber.isSuccess) {
			// setValue('document_number', documentNumber.data?.documentNumber);
		}
	}, [documentNumber.data]);

	useEffect(() => {
		if (operation) {
			setFromAccount(accounts.data?.items.find((x) => x.id == operation.account_id));
			setIbanToValidate(operation.beneficiary.accountNumber);
		} else {
			setFromAccount(accounts.data?.items[0]);
		}
	}, [accounts.data]);

	const resetForm = (status: SigningStatus) => {
		reset(undefined, { keepDefaultValues: true, keepDirty: true });
		setCreateOrderResponse({ isError: false, data: undefined });

		setCreateOrderResponse(undefined);
		setSigningStatus(status);
		setIbanToValidate(undefined);
	};

	useEffect(() => {
		if (!createTransaction.data) return;

		setCreateOrderResponse({
			isError: false,
			data: createTransaction.data,
		});
	}, [createTransaction.data]);

	useEffect(() => {
		if (!createTransaction.isError) return;
		setCreateOrderResponse({
			isError: true,
			data: createTransaction?.error?.response?.data,
		});
	}, [createTransaction.isError]);

	useEffect(() => {
		if (!watch('beneficiary.address.country')) {
			resetField('beneficiary.address.town');
			resetField('beneficiary.address.postal_code');
			resetField('beneficiary.address.building_number');
			resetField('beneficiary.address.line');
		}
	}, [watch('beneficiary.address.country')]);

	const AccountInformation = (account: Account) => {
		return (
			<Flex
				justify={'between'}
				align={'center'}
				style={{
					width: '592px',
					height: '66px',
					background: 'white',
					cursor: 'pointer',
				}}
			>
				<Flex direction={'column'} align={'start'}>
					<Typography level="text" fontWeight="400">
						{t('dashboard.accountInformationCard.accountName')}
					</Typography>
					<Typography level="h4">{account?.ownerName}</Typography>
				</Flex>
				<Flex direction={'column'} align={'start'}>
					<Typography level="text">
						{t('dashboard.accountInformationCard.accountNo')}
					</Typography>
					<Typography level="h4">{account?.iban}</Typography>
				</Flex>
				<Flex direction={'column'} align={'start'}>
					<Typography level="text">
						{t('dashboard.accountInformationCard.currency')}
					</Typography>
					<Typography level="h4">{account?.currency}</Typography>
				</Flex>
				<Flex direction={'column'} align={'start'}>
					<Typography level="text">
						{t('dashboard.accountInformationCard.balance')}
					</Typography>
					<Typography level="h4">{account?.availableBalance.toFixed(2)}</Typography>
				</Flex>
			</Flex>
		);
	};

	const ButtonContent = (account: Account) => {
		return (
			<Flex
				direction={'row'}
				justify={'between'}
				align={'center'}
				style={{
					width: '100%',
					height: '71px',
					marginTop: '14px',
				}}
			>
				<Flex direction={'column'}>
					<Typography level="small-text">
						{t('orders.common.accountHeaders.name')}
					</Typography>
					<Typography level="text">{account?.ownerName}</Typography>
					<Typography level="small-text">
						{t('orders.common.accountHeaders.number')}
					</Typography>
					<Typography level="text">{account?.iban}</Typography>
				</Flex>
				<Flex direction={'column'}>
					<Typography level="small-text">
						{t('orders.common.accountHeaders.currency')}
					</Typography>
					<Typography level="text">{account?.currency}</Typography>
					<Typography level="small-text">
						{t('orders.common.accountHeaders.balance')}
					</Typography>
					<Typography level="text">{account?.availableBalance.toFixed(2)}</Typography>
				</Flex>
			</Flex>
		);
	};

	const StatusMessage = () => {
		return (
			<div style={{ marginTop: '14px' }}>
				{createOrderResponse?.isError ? (
					<Alert type={'error'}>{t('orders.common.orderFailed')}</Alert>
				) : (
					createOrderResponse?.data?.id && (
						<Alert type={'success'}>{t('orders.common.orderCreated')}</Alert>
					)
				)}
				{Object.keys(formState.touchedFields).length === 0 && (
					<>
						{signingStatus === SigningStatus.ERROR && (
							<Alert type={'error'}>{t('orders.common.orderSigningFailed')}</Alert>
						)}
						{signingStatus === SigningStatus.SUCCESS && (
							<Alert type={'success'}>{t('orders.common.orderSigned')}</Alert>
						)}
						{signingStatus === SigningStatus.CANCELED && (
							<Alert type={'warning'}>{t('orders.common.orderSigningCanceled')}</Alert>
						)}
					</>
				)}
			</div>
		);
	};

	const AccountBlock = () => {
		if (accounts.isLoading) {
			return (
				<div
					style={{
						display: 'flex',
						marginTop: '28px',
					}}
				>
					<Loader />
				</div>
			);
		}

		return (
			<div className={styles.accountContainer}>
				{!accounts.isError && !accounts.isLoading && fromAccount && (
					<Collapsible
						className={styles.collapsibleOperation}
						type="icon"
						label={t('orders.common.payer')}
						isOpen={isOpenAccounts}
						setOpen={() => setOpenAccounts(!isOpenAccounts)}
						buttonContent={
							isTabletOrMobile
								? ButtonContent(fromAccount)
								: AccountInformation(fromAccount)
						}
					>
						<ScrollableAccountsList
							accounts={accounts?.data?.items}
							selectedAccount={fromAccount}
							onValueChanged={(e) => {
								const acc = accounts?.data?.items.find((x) => x.id === e);
								setFromAccount(acc);
								setOpenAccounts(false);
							}}
							currentPage={currentPage}
							pageSize={ACCOUNTS_PAGE_SIZE}
							totalCount={accounts?.data?.pagination.count}
							onPageChange={setCurrentPage}
						/>
					</Collapsible>
				)}
			</div>
		);
	};

	return (
		<>
			{accounts.isError && <Alert type="error">{t('common.failedFetching')}</Alert>}

			<div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.accountContainer}>
						<Alert type="info" className={styles.alert}>
							{' '}
							{t('orders.international.alert')}
						</Alert>
						<AccountBlock />
					</div>

					<SimpleInput
						{...register('beneficiary.name', { required: true })}
						className={styles.input}
						label={t('orders.newPayment.form.name')}
						isMandatory
						isError={!!errors.beneficiary?.name}
					/>
					{errors.beneficiary?.name && (
						<span className={styles.errorText}>{errors?.beneficiary?.name.message}</span>
					)}
					<div className={styles.inputWithLoaderContainer}>
						<SimpleInput
							{...register('beneficiary.account', { required: true })}
							className={styles.input}
							label={t('orders.newPayment.form.accountNo')}
							isMandatory
							isError={!!errors.beneficiary?.account}
							onBlur={onIbanBlur}
						/>
						{validateIBAN.isLoading && (
							<div style={{ marginBottom: '8px' }}>
								<Loader />
							</div>
						)}
					</div>
					{errors.beneficiary?.account && (
						<span className={styles.errorText}>
							{errors?.beneficiary?.account.message}
						</span>
					)}

					<InputDropdown
						inputRegister={register('amount', { required: true })}
						selectorRegister={register('currency', { required: true })}
						className={styles.input}
						label={t('orders.newPayment.form.amount')}
						isMandatory
						options={geoData?.data?.currencies}
						control={control}
						inputMode="numeric"
						isError={!!errors?.amount || !!errors?.currency}
					/>
					{(errors?.amount || errors?.currency) && (
						<span className={styles.errorText}>
							{errors?.amount?.message || errors?.currency?.message}
						</span>
					)}

					<Controller
						name={'beneficiary.address.country'}
						control={control}
						rules={{ required: true }}
						render={({ ...field }) => {
							const { ref, ...rest } = field; // removes ref
							return (
								<NewSelect
									{...rest}
									label={t('orders.newPayment.additionalData.address.country')}
									options={geoData?.data?.countries}
									className={styles.input}
									allowClear
									required
									isError={!!errors.beneficiary?.address?.country}
								/>
							);
						}}
					/>

					{watch('beneficiary.address.country') && (
						<>
							<SimpleInput
								{...register('beneficiary.address.line', {
									required: true,
								})}
								isMandatory
								className={styles.input}
								label={t('orders.newPayment.additionalData.address.street')}
								isError={!!errors.beneficiary?.address?.line}
							/>

							<SimpleInput
								{...register('beneficiary.address.town', {
									required: true,
								})}
								isMandatory
								className={styles.input}
								label={t('orders.newPayment.additionalData.address.town')}
								isError={!!errors.beneficiary?.address?.town}
							/>

							<SimpleInput
								{...register('beneficiary.address.building_number', {
									required: true,
								})}
								isMandatory
								className={styles.input}
								label={t('orders.newPayment.additionalData.address.buildingNumber')}
								isError={!!errors.beneficiary?.address?.building_number}
							/>

							<SimpleInput
								{...register('beneficiary.address.postal_code', {
									required: true,
								})}
								isMandatory
								className={styles.input}
								label={t('orders.newPayment.additionalData.address.postalCode')}
								isError={!!errors.beneficiary?.address?.postal_code}
							/>
						</>
					)}

					<div className={styles.paymentDataContainer}>
						<div className={styles.paymentDataForm}>
							<Typography level="h4">{t('orders.newPayment.data.title')}</Typography>

							<Switch
								label={t('orders.newPayment.data.transferType.label')}
								className={styles.switch}
								defaultValue={transferInfoType}
								onChange={(e: string) => setTransferInfoType(e)}
								options={TRANSFER_INFO_TYPES}
								value={transferInfoType}
							/>

							{transferInfoType === 'description' ? (
								<>
									<div className={styles.label}>
										<label htmlFor={'paymentDescription'}>
											{t('orders.newPayment.data.transferType.description')}
										</label>
										<div className={styles.mandatory}>*</div>
									</div>
									<TextArea
										{...register('payment_details', { required: true })}
										id={'paymentDescription'}
										radius={'none'}
										resize={'vertical'}
										className={clsx(
											styles.textArea,
											!!errors.payment_details && styles.error,
										)}
									/>
								</>
							) : (
								<SimpleInput
									{...register('payment_details', { required: true })}
									isMandatory
									className={styles.documentNumber}
									label={t('orders.newPayment.data.transferType.refNo')}
									isError={!!errors.payment_details}
								/>
							)}
							{errors.payment_details && (
								<span className={styles.errorText}>
									{errors?.payment_details.message}
								</span>
							)}
							<Controller
								name={'execution_date'}
								control={control}
								rules={{ required: true }}
								render={({ field }) => {
									const { ref, ...rest } = field; // removes ref
									return (
										<CalendarInput
											{...rest}
											isMandatory
											isError={!!errors.execution_date}
											className={styles.date}
											label={t('orders.newPayment.data.date')}
											disabledDays={{ before: today, after: yearAfter() }}
										/>
									);
								}}
							/>
							{errors.execution_date && (
								<span className={styles.errorText}>{errors?.execution_date.message}</span>
							)}

							<Controller
								name={'fee_type'} // TODO kas cia
								control={control}
								// rules={{ required: true }}
								render={({ ...field }) => {
									const { ref, ...rest } = field; // removes ref
									return (
										<NewSelect
											{...rest}
											label={t('orders.createdPayment.additionalPaymentData.feeType')}
											options={FEE_OPTIONS} // FEE types?
											className={styles.input}
											required
											isError={!!errors.fee_type}
											disabled={true}
										/>
									);
								}}
							/>

							<SimpleInput
								{...register('document_number', { required: true })}
								isMandatory
								className={styles.documentNumber}
								label={t('orders.newPayment.data.docNo')}
								isError={!!errors.document_number}
							/>
							{errors.document_number && (
								<span className={styles.errorText}>{errors.document_number.message}</span>
							)}

							<Typography className={styles.documentNumber} level="h4">
								{t('orders.newPayment.additionalData.beneficiaryBank')}
							</Typography>
							<div className={styles.inputWithLoaderContainer}>
								<SimpleInput
									{...register('beneficiary.bank.bic', { required: true })}
									isMandatory
									className={styles.documentNumber}
									label={t('orders.newPayment.additionalData.swift.bic')}
									isError={!!errors.beneficiary?.bank?.bic}
									leftText="SWIFT"
								/>
								{/* <div>
								<Button onClick={() => {}} type='button' variant='secondary'>Search</Button>
							</div> */}
							</div>

							<SimpleInput
								{...register('beneficiary.bank.name', { required: true })}
								isMandatory
								className={styles.documentNumber}
								label={t('orders.newPayment.additionalData.swift.additionalData.name')}
								isError={!!errors.beneficiary?.bank?.name}
							/>
							<SimpleInput
								{...register('beneficiary.bank.address.line', { required: true })}
								isMandatory
								className={styles.documentNumber}
								label={t('orders.newPayment.additionalData.swift.additionalData.address')}
								isError={!!errors.beneficiary?.bank?.address?.line}
							/>
							<Controller
								name={'beneficiary.bank.address.country'}
								control={control}
								rules={{ required: true }}
								render={({ ...field }) => {
									const { ref, ...rest } = field; // removes ref
									return (
										<NewSelect
											{...rest}
											label={t('orders.newPayment.additionalData.address.country')}
											options={geoData?.data?.countries}
											className={styles.input}
											allowClear
											required
											isError={!!errors.beneficiary?.bank?.address?.country}
										/>
									);
								}}
							/>
						</div>

						<Collapsible
							isOpen={filtersOpen}
							setOpen={setFiltersOpen}
							buttonContent={t('orders.newPayment.additionalData.correspondentBank')}
						>
							<AdditionalDataForm
								register={register}
								control={control}
								errors={errors}
								watch={watch}
							/>
						</Collapsible>
						<div>
							{!createOrderResponse?.data?.id && (
								<div style={{ display: 'flex', flexDirection: 'row', columnGap: '16px' }}>
									<Button
										className={clsx(
											styles.createButton,
											isTabletOrMobile && styles.fullWidth,
										)}
										variant="primary"
										type={'submit'}
										disabled={
											!!createOrderResponse?.data?.id || createTransaction.isPending
										}
									>
										{t('orders.common.createButton')}
									</Button>
								</div>
							)}
							<StatusMessage />
						</div>
					</div>
				</form>
				{createOrderResponse?.data?.id && canSign && (
					<div style={{ marginTop: '14px' }}>
						<TFA transactionId={createOrderResponse?.data?.id} onSign={resetForm} />
					</div>
				)}
				<FormBlocker
					when={
						(Object.keys(formState.dirtyFields).length > 0 || operation) &&
						!createOrderResponse?.data
					}
				/>
			</div>
		</>
	);
};

export default SwiftPaymentForm;
