import { useMutation, useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { useBankProfile } from '../../contexts/BankProfileContext';
import { useAuth } from '../../hooks';
import { PaginatedItems } from './common';
import { Transaction } from './types/getTransactions';
import { OPERATION_TYPE } from '../../pages/operations/operations';

export enum BATCH_STATUS {
	INITIALIZING = 'INITIALIZING',
	UNSIGNED = 'UNSIGNED',
	SIGNING = 'SIGNING',
	SIGNED = 'SIGNED',
	FAILED = 'FAILED',
}

export interface BatchItem {
	uuid: string;
	fileName: string | null;
	status: BATCH_STATUS;
	uploadedDate: string;
	operationCount: number;
	errorCount: number;
	totalAmount: number;
}

interface BatchResponse {
	headers: any[];
	original: PaginatedItems<BatchItem[]>;
	exception: any;
}

export interface ListBatchTransactionsResponse {
	uuid: string;
	accountBalance: number;
	costOfOperations: number;
	totalAmount: number;
	accountName: string;
	accountIban: string;
	currency: string;
	status: string;
	uploadDate: string;
	operationsCount: number;
	errorCount: number;
	fileName: string;
	transactions: PaginatedItems<Transaction[]>;
}

export const BATCH_TRANSACTIONS_PAGE_SIZE = 10;
export const BATCH_LIST_PAGE_SIZE = 10;

const useBatchUpload = () => {
	const { selectedProfile } = useBankProfile();
	const { session } = useAuth();

	const uploadBatch = async (file: File): Promise<{ batchId: string }[]> => {
		const formData = new FormData();
		formData.append('transactions', file);

		const response = await axios.post(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${selectedProfile?.id}/transactions/batch`,
			formData,
			{
				headers: {
					Authorization: session?.tokenized,
					'Content-Type': 'multipart/form-data',
				},
			},
		);

		return response.data;
	};

	return useMutation({
		mutationFn: uploadBatch,
		onSuccess: async (data) => {
			return data;
		},
		onError: async (data) => {
			return data;
		},
	});
};

function useListBatches(
	limit = BATCH_LIST_PAGE_SIZE,
	currentPage = 1,
	type?: OPERATION_TYPE,
	dateFrom?: string,
	dateTo?: string,
	status?: string[],
): PaginatedItems<BatchItem[]> {
	const { session } = useAuth();
	const { selectedProfile } = useBankProfile();
	const offset = currentPage === 1 ? 0 : (currentPage - 1) * limit;

	const listBatches = async (): Promise<BatchResponse> => {
		const response = await axios.get(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${selectedProfile.id}/transactions/batch`,
			{
				headers: { Authorization: session?.tokenized },
				params: {
					offset,
					limit,
					...(dateFrom && { dateFrom }),
					...(dateTo && { dateTo }),
					...(type && { paymentNetwork: type }),
					...(status?.length > 0 && {
						status: status?.map((s) => s.toUpperCase()).join(','),
					}),
				},
			},
		);

		return response.data;
	};

	return useQuery({
		queryKey: ['list-batches', limit, currentPage, type, dateFrom, dateTo, status],
		queryFn: () => listBatches(),
		select: (data) => {
			return {
				items: data.original.items.map((item) => ({
					...item,
					totalAmount: item.totalAmount / 100,
				})),
				pagination: data.original.pagination,
			};
		},
	});
}

function useListBatchTransactions(
	batchId: string,
	limit = BATCH_TRANSACTIONS_PAGE_SIZE,
	currentPage: number,
): UseQueryResult<PaginatedItems<Transaction[]>> {
	const { session } = useAuth();
	const { selectedProfile } = useBankProfile();

	const offset = currentPage === 1 ? 0 : (currentPage - 1) * limit;

	return useQuery({
		queryKey: ['batch-transactions', batchId, limit, currentPage],
		queryFn: () =>
			listTransactions(batchId, selectedProfile.id, limit, offset, session?.tokenized),
		select: (data) => {
			return {
				...data.transactions,
				items: data.transactions.items.map((item) => ({
					...item,
					amount: item.amount / 100,
				})),
			};
		},
	});
}

function useListBatchTransactions2(
	batchIds: string[],
	limit = BATCH_TRANSACTIONS_PAGE_SIZE,
	currentPage = 1,
): {
	data: ListBatchTransactionsResponse[] | undefined;
	isLoading: boolean;
	isPending: boolean;
	isError: boolean;
	isSuccess: boolean;
	error: Error | null;
} {
	const { session } = useAuth();
	const { selectedProfile } = useBankProfile();
	const offset = currentPage === 1 ? 0 : (currentPage - 1) * limit;

	return useQueries({
		queries: batchIds.map((batchId) => ({
			queryKey: ['batch-transactions', batchId, limit, currentPage],
			queryFn: () =>
				listTransactions(batchId, selectedProfile.id, limit, offset, session?.tokenized),
		})),
		combine: (data: UseQueryResult<ListBatchTransactionsResponse, Error>[]) => {
			const isLoading = data.some((query) => query.isLoading);
			const isPending = data.some((query) => query.isPending);
			const isSuccess = data.some((query) => query.isSuccess);
			const isError = data.some((query) => query.isError);
			const error = data.find((query) => query.error)?.error || null;
			const result =
				!isLoading && !isError
					? data.flatMap((query) => {
							if (!query.data) return [];
							return [
								{
									...query.data,
									totalAmount: query.data.totalAmount / 100,
									transactions: {
										...query.data.transactions,
										items: query.data.transactions.items.map((item) => ({
											...item,
											amount: item.amount / 100,
										})),
									},
								},
							];
						})
					: undefined;

			return {
				data: result,
				isLoading,
				isSuccess,
				isPending,
				isError,
				error,
			};
		},
	});
}

const listTransactions = async (
	batchId: string,
	profileId: string,
	limit: number,
	offset: number,
	token?: string,
): Promise<ListBatchTransactionsResponse> => {
	const response = await axios.get(
		`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${profileId}/transactions/batch/${batchId}`,
		{
			headers: { Authorization: token },
			params: {
				offset,
				limit,
			},
		},
	);

	return response.data;
};

function useSignBatch() {
	const { session } = useAuth();
	const { selectedProfile } = useBankProfile();

	const signTransaction = async (props: { transactionId: string; code: string }) => {
		const { transactionId } = props;

		const response = await axios.patch(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${selectedProfile.id}/transactions/batch/${transactionId}/sign`,
			{
				code: props.code,
			},
			{
				headers: { Authorization: session?.tokenized },
			},
		);

		return response.data;
	};

	return useMutation({
		mutationFn: signTransaction,
		onSuccess: async (data) => {
			return data;
		},
		onError: async (data) => {
			return data;
		},
	});
}

function useInitiateSigningBatch() {
	const { session } = useAuth();
	const { selectedProfile } = useBankProfile();

	const initiateSigning = async (props: { batchId: string }) => {
		const { batchId } = props;

		const response = await axios.patch(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${selectedProfile.id}/transactions/batch/${batchId}/initiate-signing`,
			null,
			{
				headers: { Authorization: session?.tokenized },
			},
		);

		return response.data;
	};

	return useMutation({
		mutationFn: initiateSigning,
		onSuccess: async (data) => {
			return data;
		},
		onError: async (data) => {
			return data;
		},
	});
}

function useSignMultiple() {
	const { session } = useAuth();
	const { selectedProfile } = useBankProfile();

	const signTransaction = async (props: { transactionIds: string[]; code: string }) => {
		const { transactionIds, code } = props;

		const response = await axios.patch(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${selectedProfile.id}/transactions/sign/multiple`,
			{
				transaction_uuids: transactionIds,
				code: code,
			},
			{
				headers: { Authorization: session?.tokenized },
			},
		);

		return response.data;
	};

	return useMutation({
		mutationFn: signTransaction,
		onSuccess: async (data) => {
			return data;
		},
		onError: async (data) => {
			return data;
		},
	});
}

function useInitiateSigningMultiple() {
	const { session } = useAuth();
	const { selectedProfile } = useBankProfile();

	const initiateSigning = async (props: { transactionIds: string[] }) => {
		const response = await axios.patch(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${selectedProfile.id}/transactions/initiate-signing/multiple`,
			{
				transaction_uuids: props.transactionIds,
			},
			{
				headers: { Authorization: session?.tokenized },
			},
		);

		return response.data;
	};

	return useMutation({
		mutationFn: initiateSigning,
		onSuccess: async (data) => {
			return data;
		},
		onError: async (data) => {
			return data;
		},
	});
}

export {
	useBatchUpload,
	useListBatches,
	useListBatchTransactions,
	useListBatchTransactions2,
	useSignBatch,
	useInitiateSigningBatch,
	useInitiateSigningMultiple,
	useSignMultiple,
};
