import { toast } from 'sonner';
import { ErrorItem } from '../../contexts/ErrorContext';
import { Flex } from '@radix-ui/themes';
import { ErrorAlertIcon } from '../icons';
import Typography from '../typography/typography';
import i18n from '../../i18n';

export const toast422 = (errors: ErrorItem[]) => {
	const t = i18n.getFixedT(i18n.language, '', '');

	return toast(t('common.errorOccured'), {
		description: (
			<>
				{errors.map((error) => (
					<Flex key={error.property} gap="2" direction="column">
						<Typography fontSize={'12px'} level="small-text">
							{error.message}
						</Typography>
					</Flex>
				))}
			</>
		),
		icon: <ErrorAlertIcon />,
		duration: 10000,
	});
};

export const toast400 = (error: string) => {
	const t = i18n.getFixedT(i18n.language, '', '');

	return toast(t('common.errorOccured'), {
		description: (
			<>
				<Typography level="small-text">{error}</Typography>
			</>
		),
		icon: <ErrorAlertIcon />,
		duration: 10000,
	});
};
