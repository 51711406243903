import { createContext, useContext, ReactNode, useState, useCallback } from 'react';

export interface ErrorItem {
	property: string;
	message: string;
}

interface ErrorContextType {
	errors: ErrorItem[];
	setErrors: (errors: ErrorItem[]) => void;
	addError: (error: ErrorItem) => void;
	clearErrors: () => void;
	clearError: (property: string) => void;
}

interface ErrorProviderProps {
	children: ReactNode;
	initialErrors?: ErrorItem[];
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export function ErrorProvider({ children, initialErrors = [] }: ErrorProviderProps) {
	const [errors, setErrorState] = useState<ErrorItem[]>(initialErrors);

	const setErrors = useCallback((errors: ErrorItem[]) => {
		setErrorState(errors);
	}, []);

	const addError = useCallback((error: ErrorItem) => {
		setErrorState((prev) => [...prev, error]);
	}, []);

	const clearErrors = useCallback(() => {
		setErrorState([]);
	}, []);

	const clearError = useCallback((property: string) => {
		setErrorState((prev) => prev.filter((error) => error.property !== property));
	}, []);

	return (
		<ErrorContext.Provider
			value={{ errors, setErrors, addError, clearErrors, clearError }}
		>
			{children}
		</ErrorContext.Provider>
	);
}

export function useError() {
	const context = useContext(ErrorContext);

	if (context === undefined) {
		throw new Error('useError must be used within an ErrorProvider');
	}

	return context;
}
