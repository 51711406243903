import * as Dialog from '@radix-ui/react-dialog';
import Button from '../../components/button';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './deleteOrderModal.module.css';

const DeleteOrderModal = ({ onAccept }: { onAccept: () => void }) => {
	const [modalOpen, setModalOpen] = useState(false);
	const { t } = useTranslation(undefined, {
		keyPrefix: 'listOfOperations.deleteOperation',
	});

	const handleOnClick = () => {
		onAccept();
	};

	return (
		<Dialog.Root open={modalOpen}>
			<Dialog.Trigger asChild>
				<Button
					variant={'secondary'}
					doNotShrinkOnMobile
					onClick={() => setModalOpen(!modalOpen)}
					className={styles.triggerButton}
				>
					{t('button')}
				</Button>
			</Dialog.Trigger>

			<Dialog.Overlay className={styles.DialogOverlay} />
			<Dialog.Content
				className={styles.DialogContent}
				onCloseAutoFocus={(e) => e.preventDefault()}
				onPointerDownOutside={() => setModalOpen(!modalOpen)}
				onEscapeKeyDown={() => setModalOpen(!modalOpen)}
			>
				<Dialog.Title className={styles.DialogTitle}>{t('description')}</Dialog.Title>
				<div className={styles.dialogButtonsContainer}>
					<Dialog.Close>
						<Button className={styles.button} variant={'primary'} onClick={handleOnClick}>
							{t('delete')}
						</Button>
					</Dialog.Close>
					<Dialog.Close asChild>
						<Button
							className={styles.button}
							variant={'secondary'}
							onClick={() => setModalOpen(!modalOpen)}
						>
							{t('cancel')}
						</Button>
					</Dialog.Close>
				</div>
			</Dialog.Content>
		</Dialog.Root>
	);
};

export default DeleteOrderModal;
