import './index.css';
import './variables.css';
import './i18n';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { Flex, Theme } from '@radix-ui/themes';
import '@radix-ui/themes/styles.css';
import './theme-config.css';
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
} from 'react-router-dom';
import Dashboard from './pages/dashboard/dashboard.tsx';
import Layout from './components/layout/layout.tsx';
import Accounts from './pages/accounts/accounts.tsx';
import Orders from './pages/orders/orders.tsx';
import AccountStatement from './pages/accountStatement/accountStatement.tsx';
import Info from './pages/info/info.tsx';
import Settings from './pages/settings/settings.tsx';
import Messages from './pages/messages/messages.tsx';
import Operations from './pages/operations/operations.tsx';
import {
	MutationCache,
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query';
import SingleOrder from './pages/orders/specificOrder.tsx';
import OryLogin from './pages/login/ory-login.tsx';
import { AuthProvider } from './contexts/AuthContext.tsx';
import { Navigate } from 'react-router-dom';
import ProtectedRoute from './components/protectedRoute';
import UserEditScreen from './pages/settings/userEditScreen.tsx';
import SettingsLayout from './pages/settings/settingsLayout.tsx';
import AccountEditScreen from './pages/settings/accountEditScreen.tsx';
import MessagesLayout from './pages/messages/messagesLayout.tsx';
import NewMessageScreen from './pages/messages/newMessage.tsx';
import { ProfileProvider } from './contexts/BankProfileContext.tsx';
import { AxiosError } from 'axios';
import { GeoDataProvider } from './contexts/GeoDataContext';
import BatchOperations from './pages/operations/batch/batch.tsx';
import * as Sentry from '@sentry/react';
import { FeatureProvider } from './contexts/FeatureContext.tsx';
import { ErrorItem, ErrorProvider } from './contexts/ErrorContext.tsx';
import { Toaster } from 'sonner';
import ErrorAlertIcon from './components/icons/errorAlertIcon.tsx';
import { toast400, toast422 } from './components/toast/toast.tsx';
import styles from './components/toast/toast.module.css';

const FAIL_SILENTLY_URLS = ['/api/v1/utilities/bank-info'];
interface Error422 {
	status: boolean;
	status_code: number;
	error_message: string;
	errors: {
		[key: string]: string[];
	};
}

interface Error400 {
	status: boolean;
	status_code: number;
	error_message: string;
	errors: { message: string };
}

const globalErrors: ErrorItem[] = [];

const handle422 = (err: AxiosError) => {
	const errors = (err.response?.data as Error422)?.errors;

	const mappedErrors = Object.keys(errors).map((key: string) => {
		return { property: key, message: errors[key][0] };
	});

	globalErrors.push(...mappedErrors);

	toast422(mappedErrors);
};

const handle400 = (err: AxiosError) => {
	const error = (err.response?.data as Error400)?.errors;

	toast400(error.message);
};

const isFailSilently = (err: AxiosError) => {
	const url = new URL(err.config?.url || '').pathname;

	// Get the last segment index
	const lastSlashIndex = url.lastIndexOf('/');

	// If there's a path parameter, remove it
	const baseUrl = lastSlashIndex > 0 ? url.substring(0, lastSlashIndex) : url;
	return FAIL_SILENTLY_URLS.includes(baseUrl);
};

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: 1,
		},
	},
	queryCache: new QueryCache({
		onError: (error: Error) => {
			const axiosError = error as AxiosError;
			if (axiosError && axiosError.response?.status === 401) {
				const basePath =
					import.meta.env.VITE_ENVIRONMENT === 'production'
						? import.meta.env.VITE_ORY_BASE_PATH
						: 'http://localhost:4000';
				// Redirect to login page
				// window.location.replace(`${basePath}/ui/login`);
			}
			if (axiosError && axiosError.response?.status === 422) {
				handle422(axiosError);
			}
			if (
				axiosError &&
				axiosError.response?.status === 400 &&
				!isFailSilently(axiosError)
			) {
				handle400(axiosError);
			}
		},
	}),
	mutationCache: new MutationCache({
		onError: (error: Error) => {
			const axiosError = error as AxiosError;
			if (axiosError && axiosError.response?.status === 401) {
				const basePath =
					import.meta.env.VITE_ENVIRONMENT === 'production'
						? import.meta.env.VITE_ORY_BASE_PATH
						: 'http://localhost:4000';
				// Redirect to login page
				// window.location.replace(`${basePath}/ui/login`);
			}
			if (axiosError && axiosError.response?.status === 422) {
				handle422(axiosError);
			}
			if (axiosError && axiosError.response?.status === 400) {
				handle400(axiosError);
			}
		},
	}),
});

const router = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route
				path="/"
				element={
					<ProtectedRoute>
						<Layout />
					</ProtectedRoute>
				}
			>
				<Route index element={<Navigate to={'dashboard'} />} />
				<Route path="/dashboard" element={<Dashboard />} />
				<Route path="/accounts" element={<Accounts />} />
				<Route path="/accounts-statement/:id" element={<AccountStatement />} />
				<Route path="/orders" element={<Orders />} />
				<Route path="/orders/:id" element={<SingleOrder />} />
				<Route path="/operations">
					<Route path="/operations" element={<Operations />} />
					<Route path="/operations/batch" element={<BatchOperations />} />
				</Route>
				{/* <Route path="/messages" element={<MessagesLayout />}>
					<Route path="/messages" element={<Messages />} />
					<Route path="/messages/new" element={<NewMessageScreen />} />
					<Route path="/messages/:id/reply" element={<NewMessageScreen />} />
				</Route> */}
				<Route path="/settings" element={<SettingsLayout />}>
					<Route path="/settings" element={<Settings />} />
					<Route path="/settings/user" element={<UserEditScreen />} />
					<Route path="/settings/account/:id" element={<AccountEditScreen />} />
				</Route>
				<Route path="/info" element={<Info />} />
			</Route>
			<Route path="/login" element={<OryLogin />} />
		</>,
	),
);

if (import.meta.env.VITE_ENVIRONMENT === 'production') {
	Sentry.init({
		dsn: 'https://3cd445a7b9606c69416b7967b83abbb6@o4504142508392448.ingest.us.sentry.io/4508256105529344',
		integrations: [],
	});
}

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<Theme accentColor="red">
			<AuthProvider>
				<QueryClientProvider client={queryClient}>
					<GeoDataProvider>
						<ProfileProvider>
							<FeatureProvider>
								<ErrorProvider initialErrors={globalErrors}>
									<RouterProvider router={router}></RouterProvider>
									<Toaster
										closeButton
										toastOptions={{
											classNames: {
												icon: styles.icon,
												title: styles.title,
											},
											style: {
												borderRadius: '0px',
												maxWidth: '60vw',
												width: '430px',
											},
										}}
									/>
								</ErrorProvider>
							</FeatureProvider>
						</ProfileProvider>
					</GeoDataProvider>
				</QueryClientProvider>
			</AuthProvider>
		</Theme>
	</React.StrictMode>,
);
