import { Box, Flex } from '@radix-ui/themes';
import styles from './batchScreen.module.css';
import { FileIcon } from '@radix-ui/react-icons';
import Typography from '../../../components/typography/typography';
import { useTranslation } from 'react-i18next';
import FileInput from '../../../components/fileInput';
import TransactionsList from './transacrtionsList';
import {
	ListBatchTransactionsResponse,
	useBatchUpload,
	useListBatchTransactions2,
} from '../../../api/hooks/useBatch';
import { useEffect, useState } from 'react';
import Loader from '../../../components/loader';
import * as Separator from '@radix-ui/react-separator';
import Alert from '../../../components/alert';

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB in bytes

const BatchScreen = () => {
	const { t } = useTranslation(undefined, { keyPrefix: 'orders.batch' });
	const [file, setFile] = useState<File | null>(null);
	const [batches, setBatches] = useState<ListBatchTransactionsResponse[]>([]);
	const [batchIds, setBatchIds] = useState<string[]>([]);
	const uploadBatch = useBatchUpload();
	const listBatchTransactions = useListBatchTransactions2(batchIds);

	const handleFileSelect = (file: File) => {
		setFile(file);
		uploadBatch.mutate(file);
	};

	useEffect(() => {
		const ids = uploadBatch.data?.map((x) => x.batchId) || [];
		setBatchIds(ids);
	}, [uploadBatch.isSuccess]);

	useEffect(() => {
		if (uploadBatch.isSuccess && listBatchTransactions.isSuccess) {
			setBatches(listBatchTransactions.data || []);
		}
	}, [uploadBatch.isSuccess, listBatchTransactions.isSuccess]);

	const CardSection = (props: { prefix: string }) => (
		<Box className={styles.card}>
			<Flex gap="2" align="center">
				<FileIcon className={styles.icon} />
				<Flex direction="column">
					<Typography level="xsmall-text">{t(`${props.prefix}.title`)}</Typography>
					<Typography level="text">{t(`${props.prefix}.description`)}</Typography>
				</Flex>
			</Flex>
		</Box>
	);

	return (
		<div className={styles.container}>
			<Flex
				justify="center"
				align="center"
				direction="column"
				gap="2"
				style={{ marginBottom: '16px' }}
			>
				<Alert type="info">{t('alert3')}</Alert>
				<Alert type="info">{t('alert1')}</Alert>
				<Alert type="info">{t('alert2')}</Alert>
			</Flex>
			<Flex justify="center" className={styles.cardsContainer}>
				<CardSection prefix="card1" />
				<CardSection prefix="card2" />
			</Flex>
			<Flex justify="center">
				<FileInput
					disabled={uploadBatch.isPending || listBatchTransactions.isPending}
					onFileSelect={handleFileSelect}
					accept=".xml"
					size={MAX_FILE_SIZE}
					buttonText={t('button')}
				/>
			</Flex>
			{(uploadBatch.isPending || listBatchTransactions.isPending) && (
				<Flex
					justify="center"
					align="center"
					direction="column"
					className={styles.loaderContainer}
				>
					<Loader />
					<Typography level="text">{file?.name}</Typography>
				</Flex>
			)}

			{uploadBatch.isError && (
				<div className={styles.batchError}>
					<Alert type="error">{t('alerts.uploadError')}</Alert>
				</div>
			)}

			{listBatchTransactions.isError && (
				<div className={styles.batchError}>
					<Alert type="error">{t('alerts.listError')}</Alert>
				</div>
			)}

			{listBatchTransactions.data?.length > 0 && (
				<div style={{ marginTop: '32px' }}>
					{listBatchTransactions.data?.map((batch, i) => (
						<>
							<TransactionsList batch={batch} />
							{i < batches.length - 1 && (
								<Separator.Root
									decorative
									orientation="horizontal"
									className={styles.separator}
								/>
							)}
						</>
					))}
				</div>
			)}
		</div>
	);
};

export default BatchScreen;
